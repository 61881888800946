<template>
  <div class="login">
    <!-- <div v-if="showApplicationDownMessage" class="maintenance-alert">
      <div>We are currently undergoing scheduled maintenance from 4am to 5am PST. Your service will not be interrupted.</div>
    </div> -->
    <b-container class="thisContainer">
      <!-- Sign Up  -->
      <div>
        <b-row class="signUp">
          <b-col
            md="5"
            sm="12"
            class="bg-blue text-light signUpLeft animate__animated animate__slideInRight"
          >
            <h3 style="font-size: 28px">Welcome Back !</h3>
            <p class="my-5">
              Sign into your PhoneBox account and keep track of all your account
              information.
            </p>
            <div class="text-center">
              <button
                class="loginBtn"
                style="font-size: 18px"
                @click="$router.push('/login')"
              >
                Sign In
              </button>
            </div>
          </b-col>

          <b-col
            md="7"
            sm="12"
            class="bg-white signUpRight animate__animated animate__slideInLeft"
          >
            <b-form @submit.prevent>
              <h3 class="title mb-4">Create An Account</h3>

              <div v-show="step === 0">
                <p>1. Please enter your PhoneBox phone number</p>
                <!-- number  -->
                <div class="inputWrapper">
                  <b-form-input
                    v-model="newNumber"
                    type="tel"
                    class="mt-2 p-4 pl-5"
                    placeholder="Phone Number"
                    :state="state.number"
                    required
                  ></b-form-input>
                  <i class="fas fa-phone"></i>
                  <p
                    v-show="!state.number && state.numberWrongReason !== ''"
                    class="m-0 p-2 text-danger"
                  >
                    {{ state.numberWrongReason }}
                  </p>
                </div>
                <div class="text-center mt-4">
                  <button
                    class="mybtn"
                    style="font-size: 14px"
                    @click="checkPhoneNumber()"
                  >
                    next
                  </button>
                </div>
              </div>

              <!-- email  -->
              <b-form @submit.prevent>
                <div v-show="step === 1">
                  <img
                    @click="step--"
                    class="mb-3 buttonStyle"
                    src="../../assets/left.png"
                    width="25px"
                    height="25px"
                    alt="left icon"
                  />
                  <p>
                    2. Please enter the email address that is assosciated with
                    this number.
                  </p>
                  <div class="inputWrapper">
                    <b-form-input
                      v-model="newEmail"
                      type="tel"
                      class="mt-2 p-4 pl-5"
                      placeholder="Email Address"
                      :state="state.email"
                      required
                    ></b-form-input>
                    <i class="fas fa-envelope"></i>
                    <p
                      v-show="!state.email && state.emailWrongReason !== ''"
                      class="m-0 p-2 text-danger"
                    >
                      {{ state.emailWrongReason }}
                    </p>
                  </div>
                  <div class="text-center mt-4">
                    <button
                      class="mybtn"
                      style="font-size: 14px"
                      @click="checkEmailAddress()"
                    >
                      next
                    </button>
                  </div>
                </div>
              </b-form>

              <!-- password  -->
              <b-form @submit.prevent>
                <div v-show="step === 2">
                  <img
                    @click="step--"
                    class="mb-3 buttonStyle"
                    src="../../assets/left.png"
                    width="25px"
                    height="25px"
                    alt="left icon"
                  />
                  <p>
                    3. Please enter a password to use with your PhoneBox
                    account.
                  </p>
                  <p style="font-size: small;">
                    Password must have at least: <br />
                    - 8 characters <br />
                    - One capital letter <br />
                    - One letter and number
                  </p>
                  <div class="inputWrapper">
                    <b-form-input
                      v-model="newPassword"
                      type="password"
                      class="mt-2 p-4 pl-5"
                      placeholder="Password"
                      :state="state.password"
                      required
                    ></b-form-input>
                    <i class="fas fa-lock"></i>
                    <p
                      v-show="
                        !state.password && state.passwordWrongReason !== ''
                      "
                      class="m-0 p-2 text-danger"
                    >
                      {{ state.passwordWrongReason }}
                    </p>
                  </div>
                  <div class="text-center mt-4">
                    <button
                      class="mybtn"
                      style="font-size: 14px"
                      @click="sendValidationCode()"
                    >
                      next
                    </button>
                  </div>
                </div>
              </b-form>

              <!-- validation code  -->
              <b-form @submit.prevent>
                <div v-show="step === 3">
                  <img
                    @click="step--"
                    class="mb-3 buttonStyle"
                    src="../../assets/left.png"
                    width="25px"
                    height="25px"
                    alt="left icon"
                  />
                  <p>
                    Please enter the validation code sent to your email.
                  </p>
                  <div class="inputWrapper">
                    <b-form-input
                      v-model="newValidationCode"
                      type="password"
                      class="mt-2 p-4 pl-5"
                      placeholder="Validation Code"
                      :state="state.validationCode"
                      required
                    ></b-form-input>
                    <i class="fas fa-lock"></i>
                  </div>
                  <div v-if="isShowSendCode">
                    <p
                      @click="sendValidationCode"
                      style="text-align: center;
                cursor: pointer;
                color: blue;
                text-decoration: underline;
                margin-top: 4%;
                "
                    >
                      Resend Validation Code
                    </p>
                  </div>
                  <div v-if="!isShowSendCode">
                    <p
                      style="text-align: center; margin-top: 4%; font-size: 0.75em"
                    >
                      Resend code in {{ timer }} seconds
                    </p>
                  </div>
                  <b-form @submit.prevent>
                    <div class="text-center mt-4">
                      <button
                        class="mybtn"
                        style="font-size: 14px"
                        @click="handleSignUp()"
                      >
                        sign up
                      </button>
                    </div>
                  </b-form>
                </div>
              </b-form>
            </b-form>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      // ui bootstrap state
      state: {
        number: null,
        numberWrongReason: "",
        username: null,
        usernameWrongReason: "",
        password: null,
        passwordWrongReason: "",
        email: null,
        emailWrongReason: "",

        // validation code
        validationCode: null,
        validationCodeWrongReason: "",
      },
      showApplicationDownMessage: true,

      // ui params
      newNumber: "",
      newEmail: "",
      newUsername: "",
      newPassword: "",
      newValidationCode: "",
      signUpButtonDisabled: false,

      isCodeSent: false,

      setTimer: null,
      timer: 60,
      isShowSendCode: true,

      // May 4, 2022 new sign up flow - Yang
      step: 0,
    };
  },
  computed: {
    disableSignUpButton() {
      return this.state.number && this.state.username && this.state.password // && this.state.email
        ? false
        : true;
    },
  },
  methods: {
    checkIsReal10DigitsNumber() {
      let data = this.newNumber;
      let n = 0;
      for (let i of data) {
        if (["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(i)) {
          n++;
        }
      }
      return n === this.newNumber.length ? true : false;
    },
    checkIfUserNameExist() {
      let url = "?userName=" + this.newUsername;
      this.$axios
        .get("/LoginRegistration/CheckIfUserNameExists" + url)
        .then((response) => {
          if (response.data) {
            this.userNamedUsed = true;
          } else {
            this.userNamedUsed = false;
          }
        });
    },
    preValidationCodeEmailCheck() {
      if (
        this.state.number !== null &&
        this.state.numberWrongReason === "" &&
        this.state.email !== null &&
        this.state.emailWrongReason === "" &&
        this.state.password !== null &&
        this.state.passwordWrongReason === ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    handleLogIn() {
      this.$axios
        .get("Account/AccountInfo")
        .then((response) => {
          let data = JSON.parse(response.data);
          let clientType = data.Prepaid ? "prepaid" : "postpaid";
          this.$store.commit("setLogInStates", {
            key: "clientType",
            value: clientType,
          });
          if (clientType === "prepaid") {
            this.$router.push("/prepaid-home");
          } else {
            this.$router.push("/home");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendValidationCode() {
      if (this.state.password) {
        this.$axios
          .get(
            "/LoginRegistration/GetSMSCode?pnum=" +
              this.newEmail +
              "&usageType=02"
          )
          .then((response) => {
            let responseData = response.data;

            if (responseData === "already registerd") {
              this.$notify.error({
                title: "Error",
                message: "This account has been registered already!",
                type: "error",
              });
            } else if (responseData === "send succeed") {
              this.$notify({
                title: "Success",
                message: "Validation Code was sent, please check your email!",
                type: "success",
              });

              if (!this.isCodeSent) {
                this.step++;
                this.isCodeSent = true;
              }

              this.setTheTimer();
              this.isShowSendCode = false;
            } else {
              this.$notify.error({
                title: "Error",
                message: "Error! If this keep happening, please contact us",
                type: "error",
              });
            }
          })
          .catch((error) => {
            console.log("sendValidationCode() error", error);
          });
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error! Your password must have a minimum length of 8.",
          type: "error",
        });
      }
    },
    handleSignUp() {
      if (this.newValidationCode) {
        let trimmedCode = this.newValidationCode.trim();
        this.$axios
          .post("/LoginRegistration/Register", {
            UserID: this.newEmail,
            PhoneNum: this.newNumber,
            Password: this.newPassword,
            AccessCode: trimmedCode,
          })
          .then((response) => {
            if (response.data === "temp user already exists") {
              this.$notify.error({
                title: "Error",
                message: "Temporary user already exists!",
              });
            } else if (response.data === "temp user created") {
              this.$notify({
                title: "Success",
                message: "Temporary user is created!",
                type: "success",
              });
            } else if (response.data === "Incorrect Code") {
              console.log("response.data", response.data);
              console.log("response", response);
              this.$notify({
                title: "Error",
                message: "Your validation code is incorrect!",
              });
            } else {
              this.$notify({
                title: "Success",
                message: "Registration successful!",
                type: "success",
              });
              setTimeout(() => {
                this.$axios
                  .post("/LoginRegistration/Login1", {
                    Email: this.newEmail,
                    Password: this.newPassword,
                  })
                  .then((response) => {
                    let data = JSON.parse(response.data);
                    this.$store.commit("setLogInStates", {
                      key: "token",
                      value: data[0].Token,
                    });
                    this.$store.commit("setLogInStates", {
                      key: "cnum",
                      value: data[0].Cnum,
                    });
                    this.handleLogIn();
                  });
              }, 1000);
            }
          })
          .catch((error) => {
            console.log(error);
            this.$notify.error({
              title: "Error",
              message: "Sorry something went wrong please try again.",
            });
          });
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error! Please enter your validation code",
          type: "error",
        });
      }
    },
    setTheTimer() {
      this.timer = 60;
      this.setTimer = setInterval(() => {
        this.timer--;
      }, 1000);
    },
    checkPhoneNumber() {
      this.$axios
        .get(
          "/LoginRegistration/CheckIfPhoneNumberRegistered?phoneNumber=" +
            this.newNumber
        )
        .then((response) => {
          if (response.data === "Exsiting Account") {
            this.$notify.error({
              title: "Error!",
              message: "This number has already been registered",
            });
          } else if (response.data === "External Number") {
            this.$notify.error({
              title: "Error!",
              message:
                "Your phone number does not belong to PhoneBox's service, please register your plan first.",
            });
          } else {
            this.step++;
          }
        });
    },
    checkEmailAddress() {
      this.$axios
        .get("/LoginRegistration/ValidateEmailAddress", {
          params: {
            email: this.newEmail,
            phoneNumber: this.newNumber,
          },
        })
        .then((response) => {
          if (response.data === "true") {
            this.step++;
          } else {
            this.$notify.error({
              title: "Error!",
              message:
                "Please check your email again and ensure that this is the same email you used to purchase your PhoneBox plan",
            });
          }
        });
    },
  },
  watch: {
    newNumber(value) {
      if (value.length === 0) {
        this.state.number = null;
        this.state.numberWrongReason = "";
      } else {
        this.state.number = false;
        this.state.numberWrongReason =
          "* Please input your 10 digits canadian phone number.";
        // nunber format is correct -> call api to check this number
        // if the number is an exsiting number or no phonebox number
        if (value.length === 10 && this.checkIsReal10DigitsNumber()) {
          let url = "?phoneNumber=" + this.newNumber;
          this.$axios
            .get("/LoginRegistration/CheckIfPhoneNumberRegistered" + url)
            .then((response) => {
              if (response.data === "Exsiting Account") {
                this.state.numberWrongReason =
                  "* Phone number has already been registered, please sign in.";
              } else if (response.data === "External Number") {
                this.state.numberWrongReason =
                  "* Your phone number does not belong to PhoneBox's service, please register your plan first.";
              } else {
                this.state.number = true;
                this.state.numberWrongReason = "";
              }
            })
            .catch((error) => {
              console.log(error);
              this.$notify.error({
                title: "Error !",
                message: "Network Error !",
              });
            });
        }
      }
    },
    newUsername(value) {
      if (value.length === 0) {
        this.state.username = null;
        this.state.usernameWrongReason = "";
      } else {
        // set default value is true
        // it should pass 3 rounds testing
        // round 1: check if the length is between 3 and 12
        // round 2: check if the string only contains letters, numbers, and _
        // round 3: check of the string has been occupied
        this.state.username = true;
        this.state.usernameWrongReason = "";
        // round 1
        if (value.length >= 3 && value.length <= 12) {
          // round 2
          for (let e of value) {
            let asciiNum = e.charCodeAt(0);
            let isUppercase = asciiNum >= 65 && asciiNum <= 90;
            let isLowerCase = asciiNum >= 97 && asciiNum <= 122;
            let isNumber = asciiNum >= 48 && asciiNum <= 57;
            let isUnderscore = asciiNum === 95;
            if (!(isUppercase || isLowerCase || isNumber || isUnderscore)) {
              this.state.username = false;
              this.state.usernameWrongReason =
                "* Usernames may only contain letters, numbers, and _.";
              break;
            }
          }
          // round 3
          if (this.state.username) {
            this.$axios
              .get("/LoginRegistration/CheckIfUserNameExists?userName=" + value)
              .then((response) => {
                if (response.data) {
                  this.state.username = false;
                  this.state.usernameWrongReason =
                    "* This username is not available.";
                }
              });
          }
        } else {
          this.state.username = false;
          this.state.usernameWrongReason =
            "* Usernames can be 3 to 12 characters long.";
        }
      }
    },
    newPassword(value) {
      // if (value.length === 0) {
      //   this.state.password = null;
      //   this.state.passwordWrongReason = "";
      // } else {
      //   if (value.length >= 8) {
      //     this.state.password = true;
      //     this.state.passwordWrongReason = "";
      //   } else {
      //     this.state.password = false;
      //     this.state.passwordWrongReason = "* Must be at least 8 characters";
      //   }
      // }
      const passwordRegex = new RegExp(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"
      );
      if (!passwordRegex.test(value)) {
        this.state.password = false;
        this.state.passwordWrongReason = "Please enter a valid password!";
      } else {
        this.state.password = true;
        this.state.passwordWrongReason = "";
      }
    },
    newValidationCode(value) {
      if (value.length === 0) {
        this.state.newValidationCode = false;
        this.state.validationCodeWrongReason =
          "Validation Code cannot be blank";
      } else {
        this.state.newValidationCode = true;
      }
    },
    newEmail(value) {
      if (value.length === 0) {
        this.state.email = null;
        this.state.emailWrongReason = "";
      } else if (this.newNumber === "") {
        this.state.email = false;
        this.state.emailWrongReason = "* Please input your phone number first.";
      } else {
        this.$axios
          .get("/LoginRegistration/ValidateEmailAddress", {
            params: {
              email: this.newEmail,
              phoneNumber: this.newNumber,
            },
          })
          .then((response) => {
            if (response.data === "true") {
              this.state.email = true;
              this.state.emailWrongReason = "";
            } else {
              this.state.email = false;
              this.state.emailWrongReason =
                "* Email not matching the one in activation. Or duplicated account";
            }
          });
      }
    },
    timer(value) {
      if (value === 0) {
        clearInterval(this.setTimer);
        this.isShowSendCode = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.maintenance-alert {
  margin-top: 6px;
  background-color: rgb(233, 63, 37);
  padding: 0.5rem 0rem;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
}
.thisContainer {
  padding-top: 4%;
  width: 800px;
}

.signInLeft {
  padding: 60px 100px;
  border-radius: 10px 0px 0px 10px;
}

.signInRight {
  padding: 60px;
  border-radius: 0px 10px 10px 0px;
}

.signUpLeft {
  padding: 60px;
  border-radius: 10px 0px 0px 10px;
}

.signUpRight {
  padding: 60px 100px;
  border-radius: 0px 10px 10px 0px;
}

.title {
  font-size: 28px;
  color: #0394ca;
}

.inputWrapper {
  position: relative;
  font-size: 18px !important;
  i {
    color: #d2d2d2;
    position: absolute;
    font-size: 18px;
    top: 16px;
    left: 16px;
  }
}

.loginBtn {
  padding: 10px 40px;
  border-radius: 50px;
  border: 2px solid #fff;
  font-weight: 500;
  color: #fff;
  outline: none !important;
  text-transform: uppercase;
  background: transparent;
}

.forgetPasswordStyle {
  font-size: 15px;
}
.forgetPasswordStyle:hover {
  opacity: 0.7s;
  cursor: pointer;
  color: #0394ca;
}

.googleWrapper {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
}

.googleWrapper:hover {
  cursor: pointer;
}

::placeholder {
  opacity: 0.3; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.3;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 0.3;
}

@media (max-width: 768px) {
  .thisContainer {
    padding-top: 0px;
    width: 100%;
    margin-top: -10px;
  }
  .signInLeft,
  .signInRight,
  .signUpLeft,
  .signUpRight {
    padding: 60px;
    border-radius: 0px;
  }
}
</style>
